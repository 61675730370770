import { Row, Col } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import i18n from "i18next";
import {
  FooterSection,
  Title,
  Para,
  Large,
  Chat,
  Empty,
  Language,
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: { t: TFunction }) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
            <Language>{t("Contact")}</Language>
              <a href="https://wa.me/76011357" target="_blank" rel="noopener noreferrer">
              Whatsapp
              </a>
              <br />
              <a href="https://www.instagram.com/muke_tutoring?igsh=MW52cmIyMjVkbDlkZA==" target="_blank" rel="noopener noreferrer">
              Instagram
              </a>
              <br />
              <a href="https://www.facebook.com/profile.php?id=61555444822237&mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
                Facebook
              </a>
            </Col>
            <Col lg={8} md={8} sm={12} xs={12}>
              <Title>{t("Adresse")}</Title>
              <Para>Jounieh</Para>
              <Para>Atcl street</Para>
              <Para>12 b </Para>

            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>

            </Col>
          </Row>
        </Container>
      </FooterSection>
  
    </>
  );
};

export default withTranslation()(Footer);
